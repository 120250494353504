#business-listing-page {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}
#business-listing-page .header-img-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
#business-listing-page .header-img-container .glide__slide {
  height: auto;
}
#business-listing-page .header-img-container .glide__bullets {
  bottom: 1em;
}
#business-listing-page .header-img-container .header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#business-listing-page .content-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#business-listing-page .content-wrapper .header-text {
  font-weight: bold;
  font-size: 32px;
}
@media (min-width: 768px) {
  #business-listing-page .content-wrapper .header-text {
    margin-top: 0;
    font-size: 48px;
    margin-bottom: 20px;
  }
}
#business-listing-page .content-wrapper .description {
  font-size: 16px;
}
@media (min-width: 768px) {
  #business-listing-page .content-wrapper .description {
    max-width: 600px;
  }
}
#business-listing-page .content-wrapper .hours p {
  margin-bottom: 0;
}
#business-listing-page .content-wrapper .hours .hours-list {
  margin-top: 10px;
  width: 270px;
}
#business-listing-page .offer-content {
  background-color: #FDF3E0;
  padding: 12px;
}
#business-listing-page .offer-content.redeemed {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
#business-listing-page .offer-content .offer-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
#business-listing-page .comm-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
#business-listing-page .comm-links a {
  margin-bottom: 10px;
}
#business-listing-page .redeem-bar {
  height: 85px;
  justify-content: space-between;
  gap: 5px;
}
@media (min-width: 768px) {
  #business-listing-page .redeem-bar {
    justify-content: center;
    gap: 50px;
  }
}
#business-listing-page .redeem-bar.PURCHASE_BATTLE {
  justify-content: space-around;
}
@media (min-width: 768px) {
  #business-listing-page .redeem-bar.PURCHASE_BATTLE {
    justify-content: center;
    gap: 20px;
  }
}
#business-listing-page .redeem-bar span {
  flex: 1;
}
@media (min-width: 768px) {
  #business-listing-page .redeem-bar span {
    flex: unset;
  }
}
#business-listing-page .redeem-bar .redeem-btn {
  padding: 12px 12px;
  flex: 1;
  max-width: 200px;
}
#business-listing-page #redeem-modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition-duration: 50ms;
}
#business-listing-page #redeem-modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition-duration: 100ms;
}
@media (max-width: 576px) {
  #business-listing-page #redeem-modal .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
}
#business-listing-page #redeem-modal .modal-dialog .modal-content {
  text-align: center;
  border-radius: 0;
  background-color: #FDF3E0;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 18px;
  padding-right: 18px;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-body .modal-title {
  margin-bottom: 10px;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-footer {
  border-top: 0;
  justify-content: space-between;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-footer span {
  flex: 1;
  text-align: left;
}
#business-listing-page #redeem-modal .modal-dialog .modal-content .modal-footer #confirm-redeem-btn {
  padding: 12px 36px;
}