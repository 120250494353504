#business-map .map-btn {
  bottom: 20px !important;
  right: 12px !important;
  border: none;
  border-radius: 50%;
  background-color: white;
  height: 45px;
  width: 45px;
  color: #343434;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#business-map .map-btn.location-btn {
  bottom: 70px !important;
}
#business-map .map-btn.center-btn {
  bottom: 20px !important;
}
#business-map .gm-style-iw {
  min-width: 200px !important;
  width: 240px !important;
}
#business-map .gm-style-iw button {
  width: 36px !important;
  height: 28px !important;
}
#business-map .gm-style-iw button span {
  width: 16px !important;
  height: 16px !important;
  margin: 0 26px !important;
}
#business-map .gm-style-iw .cb-iw-title {
  font-size: 18px;
}
#business-map .gm-style-iw .cb-iw-offer-name.redeemed {
  text-decoration: line-through;
}
#business-map .gm-style-iw .links {
  display: flex;
  justify-content: space-between;
}