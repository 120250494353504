#battle-detail-page {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}
#battle-detail-page .header-img-container {
  display: flex;
  justify-content: center;
}
#battle-detail-page .header-img-container .header-img {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
#battle-detail-page .content-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#battle-detail-page .content-wrapper .header-text {
  font-weight: bold;
  font-size: 32px;
}
@media (min-width: 768px) {
  #battle-detail-page .content-wrapper .header-text {
    margin-top: 0;
    font-size: 48px;
    margin-bottom: 20px;
  }
}
#battle-detail-page .content-wrapper .description {
  font-size: 16px;
}
@media (min-width: 768px) {
  #battle-detail-page .content-wrapper .description {
    max-width: 600px;
  }
}
#battle-detail-page .content-wrapper .post-purchase-text-section #post-purchase-content.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#battle-detail-page .content-wrapper .post-purchase-text-section #show-more-purchase-text-btn {
  margin-bottom: 20px;
  padding: 0;
  font-size: 14px;
}
#battle-detail-page .resource-list .strikethrough {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
#battle-detail-page .resource-list .hours-container {
  position: absolute;
  top: 8px;
  left: 25px;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 2px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

.battle-purchase-bar {
  height: 85px;
}
.battle-purchase-bar .purchase-bar-content {
  max-width: 700px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .battle-purchase-bar .purchase-bar-content {
    justify-content: center;
  }
}
.battle-purchase-bar .purchase-bar-content .action-btn {
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 200px;
  flex: 1;
}
@media (min-width: 768px) {
  .battle-purchase-bar {
    justify-content: center;
    gap: 20px;
  }
}
.battle-purchase-bar.SOLD_OUT {
  justify-content: space-between;
}