.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

body {
  font-family: "Work Sans", sans-serif;
  color: #343434;
  background-color: white;
}
@media (min-width: 768px) {
  body {
    background-color: inherit;
  }
}

.body-container {
  min-height: 100vh;
  background-color: white;
}

#top-navbar {
  padding: 30px 20px 0 20px !important;
  background-color: white;
  padding-top: 14px;
  padding-bottom: 14px;
}
@supports (padding-top: env(safe-area-inset-top)) {
  #top-navbar {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(14px + var(--safe-area-inset-top));
  }
}
@media (min-width: 768px) {
  #top-navbar {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 992px) {
  #top-navbar {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#top-navbar .navbar-brand {
  font-size: 18px;
}
@media (min-width: 768px) {
  #top-navbar .navbar-brand {
    font-size: 28px;
  }
}
#top-navbar .navbar-toggler {
  border: none;
}
#top-navbar .right-btn {
  margin-left: 15px;
}
#top-navbar .bb-navbar-nav {
  padding-top: 20px;
  padding-bottom: 15px;
}
@media (min-width: 992px) {
  #top-navbar .bb-navbar-nav {
    padding-top: 0;
    padding-right: 10px;
    width: 100%;
    justify-content: flex-end;
  }
  #top-navbar .bb-navbar-nav .nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
#top-navbar .bb-navbar-nav .nav-link {
  color: black;
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 576px) {
  #top-navbar .bb-navbar-nav :last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  #top-navbar .bb-navbar-nav :last-of-type .btn {
    width: 33%;
  }
}
#top-navbar .btn {
  font-weight: 700;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
}
@media (min-width: 768px) {
  #top-navbar .btn {
    font-size: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 100px;
  }
}

/***************************
  Common Stuff
***************************/
.accent-color-1 {
  background-color: #FAB96F;
}

.accent-color-2 {
  background-color: #FDCF5B;
}

.accent-color-3 {
  background-color: #FDF3E0;
}

.page-content-container {
  margin-top: 92px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@supports (padding-top: env(safe-area-inset-top)) {
  .page-content-container {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(92px + var(--safe-area-inset-top));
  }
}
.page-content-container .page-content-inner-container {
  padding: 30px 25px;
}
@media (min-width: 992px) {
  .page-content-container .page-content-inner-container {
    max-width: 35%;
  }
}

.page-header-section {
  margin-bottom: 30px;
}
.page-header-section .page-header-title {
  font-weight: bold;
  font-size: 24px;
}
.page-header-section .page-header-text {
  font-size: 16px;
}

.resource-list {
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: flex-start;
}
.resource-list .home-page-card {
  margin-bottom: 20px;
}
.resource-list .home-page-card.redeemed p, .resource-list .home-page-card.redeemed span {
  text-decoration: line-through;
}
.resource-list .home-page-card a {
  color: inherit;
}
.resource-list .home-page-card a:hover {
  text-decoration: none;
}
.resource-list .home-page-card .img-container {
  border: 1px solid lightgray;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .resource-list .home-page-card .img-container {
    height: 200px;
  }
}
.resource-list .home-page-card .card-pill {
  margin-left: 10px;
  margin-top: 13px;
  position: absolute;
  border: 1px solid #D8D8D8;
  color: #343434;
  padding: 2px 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none !important;
  background-color: white;
  z-index: 1000;
}
.resource-list .home-page-card .fave-icon {
  position: absolute;
  right: 0;
  margin-right: 30px;
  margin-left: 5px;
  font-size: 22px;
  color: white;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.resource-list .home-page-card .captions {
  padding-left: 5px;
  padding-right: 5px;
}
.resource-list .home-page-card .captions p {
  margin-bottom: 0;
}
.resource-list .home-page-card .captions .first-line {
  display: flex;
  justify-content: space-between;
}
.resource-list .home-page-card .captions .first-line .resource-name {
  margin-top: 7px;
  font-weight: bold;
  font-size: 20px;
  flex: 1;
}
@media (min-width: 768px) {
  .resource-list .home-page-card .captions .first-line .resource-name {
    padding-right: 10px;
  }
}
.resource-list .home-page-card .captions .first-line .directions-link {
  margin-top: 7px;
  font-size: 13px;
  font-weight: 500;
  width: auto;
  height: 30px;
  padding: 1px 8px;
  border-radius: 5px;
  text-decoration: underline;
  text-underline-offset: 2.5px;
}
.resource-list .home-page-card .captions .first-line .directions-link .direction-icon {
  margin-right: 3px;
}
.resource-list .home-page-card .captions .second-line-txt {
  font-size: 16px;
  display: block;
}

.font-weight-semibold {
  font-weight: 600;
}

.bizbattles-form label {
  font-weight: bold;
  font-size: 16px;
}
.bizbattles-form .asteriskField {
  display: none;
}

.bizbattles-link {
  color: #343434;
  text-decoration: underline !important;
}

.bizbattles-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
}
.bizbattles-btn.primary {
  background: #FAB96F;
  color: black;
}
.bizbattles-btn.secondary {
  background: #F7F2F2;
  color: white;
}
.bizbattles-btn.outline {
  background-color: white;
  border: 2px solid #FAB96F;
}
.bizbattles-btn.loading {
  background: black !important;
}

.bizbattles-checkbox {
  min-height: 20px;
  min-width: 20px;
  margin-right: 15px;
}

.alert-modal.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.alert-modal.show .modal-dialog {
  width: 85%;
  max-width: 300px;
}
.alert-modal.show .modal-content {
  border-radius: 10px;
}
.alert-modal.show .modal-content .modal-header {
  justify-content: center;
  border: none;
  padding-top: 1.5rem;
  padding-bottom: 0;
}
.alert-modal.show .modal-content .modal-header .modal-title {
  font-weight: bold;
}
.alert-modal.show .modal-content .modal-body .modal-msg {
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
.alert-modal.show .modal-content .modal-footer {
  border: none;
  justify-content: center;
  padding: 0;
  height: 50px;
  margin-bottom: 10px;
}
.alert-modal.show .modal-content .modal-footer .modal-footer-btn {
  width: 50%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.alert-modal.show .modal-content .modal-footer .modal-footer-btn.primary-btn {
  font-weight: 800;
}

.line-separator {
  height: 2px;
  background-color: #EFEFEF;
  margin: 25px;
}

.display-linebreak {
  white-space: pre-line;
}

.two-line-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.redeemed-overlay {
  background-color: white;
  opacity: 0.7;
  background-image: url("/static/images/checkmark-blue.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  height: 100%;
  width: 100%;
}
@media (min-width: 576px) {
  .redeemed-overlay {
    background-size: 35%;
  }
}
.redeemed-overlay.in-card {
  border-radius: 15px;
  background-size: 35%;
}
@media (min-width: 576px) {
  .redeemed-overlay.in-card {
    background-size: 22%;
  }
}
@media (min-width: 768px) {
  .redeemed-overlay.in-card {
    background-size: 35%;
  }
}

.bizbattles-card {
  text-align: center;
  box-shadow: 0 0 10px #D9D9D9;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
}
.bizbattles-card.selected {
  color: white;
  background-color: black;
}

.sheen {
  position: relative;
  overflow: hidden;
}

.sheen:after {
  animation: sheen 1.5s 1.5s forwards;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.5) 50%, transparent);
  transform: rotateZ(60deg) translate(-1em, 4em);
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(0, -4em);
  }
}