#survey-page {
  padding: 30px 25px;
  text-align: center;
}
#survey-page .header-section {
  margin-bottom: 35px;
}
#survey-page .header-section .header-title {
  color: #343434;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
#survey-page .header-section .subheader-text {
  color: #343434;
  font-size: 16px;
  margin-bottom: 0;
}
#survey-page .header-section .underline {
  text-decoration: underline;
}
#survey-page .question-text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}
#survey-page .star-rating-container .dv-star-rating-star {
  font-size: 34px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0;
}
#survey-page .question-section {
  margin-bottom: 35px;
}
#survey-page .question-section .yes-no-section {
  display: flex;
  justify-content: center;
}
#survey-page .question-section .yes-no-section .yes-no-btn {
  margin-left: 15px;
  margin-right: 15px;
  max-width: 100px;
  height: 35px;
  border-radius: 10px;
}
#survey-page .feedback-for-us-section #feedback-for-us-field {
  border: 3px solid #C2C1C1;
  border-radius: 5px;
  width: 100%;
  max-width: 450px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
}
#survey-page .submit-btn-container {
  display: flex;
  justify-content: center;
}
#survey-page .submit-btn-container .btn {
  max-width: 450px;
}