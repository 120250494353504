#home-page {
  max-width: 700px;
}
#home-page .header-wrapper {
  margin-top: 50px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#home-page .header-wrapper .header-text {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 36px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .header-text {
    margin-top: 0;
    font-size: 48px;
    margin-bottom: 20px;
  }
}
#home-page .header-wrapper .header-subtext {
  margin: 2px 15px 15px 15px;
  font-size: 16px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .header-subtext {
    max-width: 600px;
  }
}
#home-page .header-wrapper .parternship-logo {
  max-width: 250px;
}
#home-page .header-wrapper .parternship-logo.tampa {
  max-width: 200px;
  margin-top: 10px;
}
#home-page .header-wrapper .parternship-logo.cincy {
  max-width: 200px;
  max-height: 70px;
}
#home-page .header-wrapper .learn-more-btn {
  background-color: black;
  margin-top: 7px;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  padding: 3px 20px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .learn-more-btn {
    padding: 10px 60px;
  }
}