.bizbattles-admin-stats {
  margin-top: -92px;
  padding: 25px;
  font-size: 14px;
  font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
}
.bizbattles-admin-stats table {
  width: 60%;
}
.bizbattles-admin-stats .stat-val {
  text-align: right;
}